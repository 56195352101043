import Button from "@/components/Common/Buttons/Button/Button";
import Dropdown from "@/components/Common/Inputs/Dropdown/Dropdown";
import InputField from "@/components/Common/Inputs/Input/Input";
import CartItem from "@/components/Components/CartItem/CartItem";
import TextArea from "@/components/Common/Inputs/TextArea/TextArea";
import CheckBox from "@/components/Common/Inputs/CheckBox/CheckBox";
import RadioButton from "@/components/Common/Inputs/RadioButton/RadioButton";
import TruckIcon from "@/components/Common/Icons/TruckIcon";
import InfoIcon from "@/components/Common/Icons/InfoIcon";

export default {
    name: 'Cart',
    components: {
        Button,
        Dropdown,
        InputField,
        CartItem,
        TextArea,
        CheckBox,
        RadioButton,
        TruckIcon,
        InfoIcon
    },
    data() {
        return {
            orderTotals: {
                productsAmount: 4269.90,
                deliveryAmount: 18.80,
                totalAmount: 4288.70,
            },
            order: {},
            orderValidated: null,
            formErrors: {
                name: false,
                phone: false,
                email: false,
                deliveryType: false,
                deliveryCity: false,
                deliveryAddress: false,
                isInvoiceRequired: false,
                invoiceDetails: {
                    copmanyName: false,
                    companyAddress: false,
                    eik: false,
                    accountablePerson: false,
                    companyId: false,
                },
                paymentType: false,
                additionalInfo: false,
                products: false,
                productQuantities: false,
            },
            invoiceType: null,
            citiesOptions: [],
            wasFormSent: false
        }
    },
    watch: {
        order: {
            handler(newVal, oldVal){
                if (this.wasFormSent) {
                    this.validateOrder();
                }
            },
            deep: true,
            immediate: true
        }
    },
    async mounted() {
        this.order = this.initOrder();
        await this.$store.dispatch("location/getCities");
        this.cities = this.$store.state.location.cities ?? null;
        this.citiesOptions = this.$store.state.location.citiesOptions ?? null;
    },
    methods: {
        initOrder() {
            return {
                name: "",
                phone: "",
                email: "",
                deliveryType: null,
                deliveryCity: "",
                deliveryAddress: "",
                invoiceDetails: {
                    copmanyName: "",
                    companyAddress: "",
                    eik: "",
                    accountablePerson: "",
                    companyId: "",
                },
                paymentType: null,
                additionalInfo: "",
                products: [],
                productQuantities: {},

                //local only
                isInvoiceRequired: null,

                hasAdditionalInfo: 0,
            };
        },
        async sendOrder() {
            this.wasFormSent = true;
            let vm = this;
            const products = this.$store.state.cart.products;
            Object.keys(products).forEach((vendorId) => {
                Object.keys(products[vendorId]['products']).forEach((key, index) => {
                    const product = products[vendorId]['products'][key];
                    vm.order.products.push(product.id);
                    vm.order.productQuantities[product.id] = product.quantity;
                });
            });

            vm.order.products = [...new Set(vm.order.products)];

            if (this.validateOrder()) {
                const response = await this.$store.dispatch('cart/checkout', vm.order);
                this.order = this.initOrder();
                this.$store.dispatch("utils/openNotificationModal", {
                    message: this.$t('order-success-order-number') + response.id,
                    type: 'success'
                });
            } else {
                this.$store.dispatch("utils/openNotificationModal", {
                    message: this.$t('please-fill-all-inputs'),
                    type: 'error'
                });
            }
        },
        validateOrder() {
            let isValid = true;
            let vm = this;
            Object.keys(this.order).forEach((key) => {
                if (['isInvoiceRequired', 'paymentType', 'hasAdditionalInfo'].includes(key)) {
                    const validation = vm.order[key] === null;
                    vm.formErrors[key] = validation;
                    if (validation !== false) {
                        isValid = false;
                    }
                }
                const notEmpty = ['name', 'phone', 'deliveryType', 'email', 'deliveryAddress'];
                if (notEmpty.includes(key)) {
                    const validation =  vm.order[key] === null || vm.order[key].length < 2 || vm.order[key] === "";
                    vm.formErrors[key] = validation;
                    if (validation !== false) {
                        isValid = false;
                    }
                }
            });

            return isValid;
        }
    },
}