<template>

  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="16" height="16" rx="8" fill="#D9DEE2"/>
    <path
        d="M8.68359 4.37109C8.84766 4.37109 8.98633 4.42773 9.09961 4.54102C9.21289 4.6543 9.26953 4.79297 9.26953 4.95703C9.26953 5.11719 9.21094 5.25586 9.09375 5.37305C8.98047 5.48633 8.84375 5.54297 8.68359 5.54297C8.52344 5.54297 8.38477 5.48633 8.26758 5.37305C8.1543 5.25586 8.09766 5.11719 8.09766 4.95703C8.09766 4.79297 8.1543 4.6543 8.26758 4.54102C8.38086 4.42773 8.51953 4.37109 8.68359 4.37109ZM8.75977 6.69727L7.59961 10.8047C7.52148 11.082 7.48242 11.248 7.48242 11.3027C7.48242 11.3652 7.5 11.416 7.53516 11.4551C7.57422 11.4941 7.61914 11.5137 7.66992 11.5137C7.72852 11.5137 7.79883 11.4824 7.88086 11.4199C8.10352 11.2441 8.32812 10.9941 8.55469 10.6699L8.75977 10.8047C8.49414 11.2109 8.18164 11.5527 7.82227 11.8301C7.55664 12.0371 7.30273 12.1406 7.06055 12.1406C6.90039 12.1406 6.76953 12.0938 6.66797 12C6.56641 11.9023 6.51562 11.7812 6.51562 11.6367C6.51562 11.4922 6.56445 11.252 6.66211 10.916L7.42383 8.29102C7.54883 7.86133 7.61133 7.5918 7.61133 7.48242C7.61133 7.39648 7.58008 7.32617 7.51758 7.27148C7.45898 7.2168 7.37695 7.18945 7.27148 7.18945C7.18555 7.18945 7.00781 7.21094 6.73828 7.25391V7.02539L8.75977 6.69727Z"
        fill="#1A2128"/>
  </svg>
</template>
<script>
export default {
  name: 'InfoIcon',
}
</script>
<style lang="scss" scoped>
</style>